// eslint-disable-next-line no-redeclare,no-unused-vars
function addHotkeys() {
  window.keyCode = getKeyCodes();
  window.ctrlKey = false;
  window.altKey = false;
  window.onkeydown = function (evt) {
    const {keyCode} = window;
    var target = $(evt.target);
    var input;
    if (target.is('input,textarea')) input = true;
    if (evt.ctrlKey) window.ctrlKey = true;
    if (evt.altKey) window.altKey = true;

    const {guideBox} = window;

    // guide handling
    if (guideBox && $(guideBox).is(':visible') && !input) {
      if (evt.keyCode == keyCode.LEFT) guideBox.find('.prev').trigger(click);
      else if (evt.keyCode == keyCode.RIGHT)
        guideBox.find('.next').trigger(click);
      else if (evt.keyCode == keyCode.ESCAPE)
        guideBox.find('.close').trigger(click);
    }

    // view handling
    else if (evt.keyCode == keyCode.ESCAPE && (view = getFocusedView()))
      view.close();
    else if (
      (evt.keyCode == keyCode.PAGE_UP || evt.keyCode == keyCode.PAGE_DOWN) &&
      (view = getFocusedView())
    ) {
      if (evt.keyCode == keyCode.PAGE_UP) view.prev(view);
      else view.next(view);
    } else if (
      evt.altKey &&
      evt.key == 'n' &&
      (view = getFocusedView()) &&
      view.new
    ) {
      view.new();
    }

    // help handling
    else if (evt.key == 'F1') {
      buildHelp();
      evt.preventDefault();
      evt.stopPropagation();
    }
    // else if (evt.keyCode == keyCode.LEFT && window.ctrlKey && !input) {
    // 	var view = viewStack[$('#history .active').next().attr('ident')];
    // 	if (view) view.open(false, false, true);
    // }
    // else if (evt.keyCode == keyCode.RIGHT && window.ctrlKey && !input) {
    // 	var view = viewStack[$('#history .active').prev().attr('ident')];
    // 	if (view) view.open(false, false, true);
    // }
    else if (evt.keyCode == keyCode.LEFT && !input && target.is('button'))
      target.prev('button').focus();
    else if (evt.keyCode == keyCode.RIGHT && !input && target.is('button'))
      target.next('button').focus();
    if (test && user.admin) checkAdminHotkeys(evt);
  };
  window.onkeyup = function () {
    window.ctrlKey = false;
    window.altKey = false;
  };
}

// eslint-disable-next-line no-redeclare,no-unused-vars
function getFocusedView() {
  var focus = $(document.activeElement);
  var ident;
  if (focus.hasClass('modal')) {
    ident = focus.attr('ident');
    if (ident) return arraySearchAssoc(modalStack, 'ident', ident, true);
  } else {
    if (focus.hasClass('view') || focus.hasClass('modal'))
      ident = focus.attr('ident');
    else ident = focus.parents('.view').attr('ident');
    if (ident) return viewStack[ident];
  }
}

// eslint-disable-next-line no-redeclare,no-unused-vars
function getKeyCodes() {
  return {
    BACKSPACE: 8,
    COMMA: 188,
    DELETE: 46,
    DOWN: 40,
    END: 35,
    ENTER: 13,
    ESCAPE: 27,
    HOME: 36,
    LEFT: 37,
    PAGE_DOWN: 34,
    PAGE_UP: 33,
    PERIOD: 190,
    RIGHT: 39,
    SPACE: 32,
    TAB: 9,
    UP: 38,
  };
}
